<template>
    <div>
        <section class="bg-home d-flex align-items-center">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-12 text-center">
                        <img
                            src="../assets/img/404.svg"
                            class="img-fluid"
                            :alt="`Docwriter - Page not found (${appName})`"
                            title="Page not found"
                        />

                        <div class="text-uppercase mt-4 display-3">Oh ! no</div>
                        <div class="text-capitalize text-dark mb-4 error-page">Page Not Found</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 text-center">
                        <router-link to="/" class="btn btn-outline-primary mt-4">
                            Go Back
                        </router-link>

                        <router-link to="/" class="btn btn-primary mt-4 ml-2">
                            Go To Home
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { HomeIcon } from 'vue-feather-icons';
    import { appName } from '@/store';

    export default {
        components: { HomeIcon },

        data() {
            return {
                appName: appName,
            }
        }
    }
</script>
